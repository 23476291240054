<template>
  <div class="zhongguancun_right_content">
    <ZhongguancunTitle :txt="'园区租赁情况'" />
    <div class="table_box_wrap">
      <h4 class="two_title"><i></i>未租凭情况</h4>
      <div class="table_box">
        <div class="table table_wrap" border="1" style="border-collapse: collapse;border:1px solid #1a8a68;">
          <div class="table_top_border table_top_head">
            <div>
              <p class="table_box_one">楼层</p>
            </div>
            <div>
              <p class="table_box_two">房号</p>
            </div>
            <div>
              <p class="table_box_three">面积</p>
            </div>
          </div>
          <div class="table_top_border table_top_cont" v-for="(item, index) in notLeased" :key="index">
            <div class="table_top_left layer">
              <div>
                <p>{{item.floor}}</p>
                <p>层</p>
              </div>
            </div>
            <div class="table_top_rightBox">
              <div v-for="(node,i) in item.datas" :key="i" class="table_top_right">
                <div>
                  <p>{{node.room_num}}</p>
                </div>
                <div>
                  <p>{{node.area}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 class="two_title"><i></i>已租凭明细</h4>
      <div class="table_leased_wrap">
        <div class="table_leased" border="1" style="border-collapse: collapse;border:1px solid #1a8a68;">
          <div class="table_bottom_box table_bottom_head">
            <div class="bottom_num bottom_num_head table_bj">楼层</div>
            <div class="tenant name table_bj">租户名字</div>
            <div class="area table_bj">面积(㎡)</div>
            <div class="endtime table_bj">到期时间</div>
          </div>
          <div class="table_bottom_box table_bottom_cont" v-for="(item, index) in leased" :key="index">
            <div class="table_bottom_contnum bottom_num table_bj">
              <p>{{item.floor}}</p>
              <p>层</p>
            </div>
            <div class="table_bottom_right">
              <div class="table_bottom_rightBox" v-for="(node,i) in item.companys" :key="i">
                <p class="tenant">{{node.name}}</p>
                <p class="area">{{node.area}}</p>
                <p class="endtime">{{node.date}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import { getNotLeased, getLeased } from '@/api/zgc';
export default {
  data() {
    return {
      leased: [],
      notLeased: []
    }
  },
  components: {
    ZhongguancunTitle
  },
  methods: {
    setGrouping(dataList, objName, arrName) {
      let list = []
      let obj = {}
      if (dataList.length > 0) {
        for (let item of dataList) {
          if (obj[item.floor]) {
            obj[item.floor].push(item)
          } else {
            obj[item.floor] = [item]
          }
        }
        for (let key in obj) {
          list.push({
            [objName]: key,
            [arrName]: obj[key]
          })
        }
      }
      return list
    },
    async getLeasedFunc() {
      const res = await getLeased();
      const { data, msg } = res;
      if (data && msg == 'success') {
        this.leased = this.setGrouping(data, 'floor', 'companys');
        console.log('原已租赁数据', data, '处理后=>', this.leased)
      }
    },
    async getNotLeasedFunc() {
      const res = await getNotLeased();
      const { data, msg } = res;
      if (data && msg == 'success') {
        this.notLeased = this.setGrouping(data, 'floor', 'datas');
        console.log('原未租赁数据', data, '处理后=>', this.notLeased)
      }
    },
    async getData() {
      await this.getLeasedFunc();
      await this.getNotLeasedFunc();
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
.two_title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 14px;
}

.table th,
.table td {
  text-align: center;
  vertical-align: middle !important;
}

.two_title i {
  width: 7px;
  height: 16px;
  background: url(./../../assets/images/zhongguancunAttractTopLeftgreen.png) no-repeat;
  background-size: 100%;
  display: block;
  float: left;
  margin-right: 3px;
  margin-top: 4px;
}

.table_box {

  // overflow-x: auto;
  // height: 130px;
  th {
    // border: 0;
  }
}

.table_box::-webkit-scrollbar {
  display: none
}

.table_wrap {
  margin-top: 10px;

  tr {
    // border-bottom: 1px solid rgb(26, 138, 104);
  }

  th,
  td {
    border-bottom: 0;
  }
}

// .table_box_one{
//   width: 80px;
//   height: 38px;
// }
.table_wrap td {
  // min-height: 100px;
}

.table_top_th {
  border-bottom: 0 solid rgb(26, 138, 104);

  // height: 75px;
  td {
    border-color: rgb(26, 138, 104);
    // border-bottom: 1px solid rgb(26, 138, 104);
  }
}

.layer {
  // height: 66px;    
}

.bottom_num.bottom_num_head {
  width: 60px;
}

.bottom_num {
  width: 70px;
  text-align: center;
  border-right: 1px solid #1a8a68;
  // background:rgba(26, 138, 104, .8);
  background-color: rgba(3, 76, 56, .4);
  // height: 36px;
}

.table_leased {}

.table_leased th {
  font-weight: 400;
  font-size: 16px;
}

.name {
  text-align: center !important;
}

.table_leased .tenant {
  width: 185px;
  padding: 0 5px;
  box-sizing: border-box;
  // padding: 0 5px;
  // white-space: nowrap;
  white-space: nowrap;
  /* 然后然溢出的部分文字隐藏 */
  overflow: hidden;
  /* 文字溢出显示用省略号。  */
  text-overflow: ellipsis;
  text-align: left;

  p {}
}

.table_leased .area {
  width: 68px;
}

.table_leased .endtime {
  // width: 90px;
  flex: 1;
}

.twonum {
  height: 103px;
}

.trnum {
  height: 103px;
}

.trarea {}

.table_bj {
  position: relative;
}

.table_bj::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  // background:rgba(26, 138, 104, .8);
  opacity: .5;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>