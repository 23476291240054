<template>
  <div class="zhongguancun_left_content">
    <div class="top">
      <ZhongguancunTitle :txt="'园区概况'" />
      <ul>
        <li>
          <h5><i class="green"></i>
            <p>办公租赁</p>
          </h5>
          <div class="num_box">
            <div class="num_box_left num_box_left_green">
              <div class="num_box_radius num_box_radius_green">
                <div class="num_box_radius_in num_box_radius_in_green">
                  <div class="num_box_radius_img area"></div>
                </div>
              </div>
              <div class="num_box_leftCont">
                <h3 style="color: #42e5b4;">
                  <ICountUp class="num" :delay="1000" :endVal="baseData?baseData.office_area:0" />
                </h3>
                <span>办公面积(㎡)</span>
              </div>
            </div>
            <div class="num_box_right">
              <div class="echarts_radius echarts_radius_green">
                <div id="myChart" :style="{width: '100%', height: '100%'}"></div>
                <p>
                  <ICountUp class="num" :delay="1000"
                    :endVal="baseData?((baseData.office_has_rend / baseData.office_area) * 100):0" />
                  <i>(%)</i>
                </p>
              </div>
              <div class="num_box_text num_box_area">
                <div class="num_box_area_one">
                  <h5 style="margin-left: 17px;color: #42e5b4; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.office_has_rend:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(㎡)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">已租凭面积<i></i></p>
                </div>
                <div class="num_box_area_two dancolorone">
                  <h5 style="margin-left: 17px;color: #08aa7b; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.office_not_rent:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(㎡)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">未租凭面积<i></i></p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <h5><i class="blue"></i>
            <p>商业租赁</p>
          </h5>
          <div class="num_box">
            <div class="num_box_left num_box_left_blue">
              <div class="num_box_radius num_box_radius_blue">
                <div class="num_box_radius_in num_box_radius_in_blue">
                  <div class="num_box_radius_img Planfloor"></div>
                </div>
              </div>
              <div class="num_box_leftCont">
                <h3 style="color: #2a75f2;">
                  <ICountUp class="num" :delay="1000" :endVal="baseData?baseData.business_area:0" />
                </h3>
                <span>商业面积(㎡)</span>
              </div>
            </div>
            <div class="num_box_right">
              <div class="echarts_radius echarts_radius_blue">
                <div id="myChart2" :style="{width: '100%', height: '100%'}">
                </div>
                <p>
                  <ICountUp class="num" :delay="1000"
                    :endVal="baseData?(baseData.business_has_rent/baseData.business_area*100):0" />
                  <i>(%)</i>
                </p>
              </div>

              <div class="num_box_text num_box_Planfloor">
                <div class="num_box_area_one">
                  <h5 style="margin-left: 17px;color: #2a75f2; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.business_has_rent:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(㎡)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">已租凭面积<i></i></p>
                </div>
                <div class="num_box_area_two dancolortwo">
                  <h5 style="margin-left: 17px;color: #1553ba; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.business_not_rent:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(㎡)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">未租凭面积<i></i></p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li style="margin-top: 15px;">
          <h5><i class="wathet"></i>
            <p>车位租赁</p>
          </h5>
          <div class="num_box">
            <div class="num_box_left num_box_left_wathet">
              <div class="num_box_radius num_box_radius_wathet">
                <div class="num_box_radius_in num_box_radius_in_wathet">
                  <div class="num_box_radius_img vehicle"></div>
                </div>
              </div>
              <div class="num_box_leftCont">
                <h3 style="color: #2dfffe;">
                  <ICountUp class="num" :delay="1000" :endVal="baseData?baseData.car_num:0" />
                </h3>
                <span>车位总数(个)</span>
              </div>
            </div>
            <div class="num_box_right">
              <div class="echarts_radius echarts_radius_wathet">
                <div id="myChart3" :style="{width: '100%', height: '100%'}">
                </div>
                <p>
                  <ICountUp class="num" :delay="1000"
                    :endVal="baseData?(baseData.car_has_rent/baseData.car_num*100):0" />
                  <i>(%)</i>
                </p>
              </div>

              <div class="num_box_text num_box_wathet">
                <div class="num_box_area_one">
                  <h5 style="margin-left: 17px;color: #2dfffe; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.car_has_rent:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(个)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">已出租<i></i></p>
                </div>
                <div class="num_box_area_two dancolorthree">
                  <h5 style="margin-left: 17px;color: #13a1a0; font-size: 22px;text-align: right;display: flex;">
                    <ICountUp class="numarea" :delay="1000" :endVal="baseData?baseData.car_not_rent:0" />
                    <span style="color: #fff;font-size: 12px;margin-top: 8px;margin-left: 2px;">(个)</span>
                  </h5>
                  <p style="margin-left: 20px;font-size: 14px;margin-top: -5px;">未出租<i></i></p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="center">
      <ZhongguancunTitle :txt="'其他产权'" />
      <div class="center_box">
        <div class="center_left_box">
          <h5>
            <p>空调时间</p>
          </h5>
          <div>
            <p>夏天: 8:30-17:30</p>
            <p style="margin-top:3px;">冬天24小时（晚上低温运行）</p>
          </div>
        </div>
        <div class="center_right_box">
          <h5>
            <p>电梯</p>
          </h5>
          <div>
            <p>8部</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <ZhongguancunTitle :txt="'地理优势'" />
      <h3>立体交通路网 多元化出行选择</h3>
      <div class="bottom_center">
        <div>
          <i style=""></i>
          <p>距离16号线万泉河站0.4公里</p>
        </div>
        <div>
          <i></i>
          <p>项目100米内有347、400、528路等10余条公交线路</p>
        </div>
        <div>
          <i></i>
          <p>北四环西路辅路、颐和园路、北四环西路路网交织往来各地</p>
        </div>
        <div>
          <i></i>
          <p>项目100米内有347、400、528路等10余条公交线路</p>
        </div>
      </div>
      <div class="bottom_train">
        <i></i>
        <p>距离北京北站10公里，约20分钟车程距离北京西站12公里，约20分钟车程距离清河站9公里，约15分钟车程</p>
      </div>
    </div>
  </div>
</template>

<script>
import ZhongguancunTitle from '@/components/ZhongguancunTitle.vue'
import ICountUp from 'vue-countup-v2';
import { getBaseData } from '@/api/zgc';

export default {
  data() {
    return {
      showecharts: false,
      baseData: null,
    }
  },
  components: {
    ZhongguancunTitle,
    ICountUp
  },
  methods: {
    echartsload() {
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      myChart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['70%', '80%'],
            color: ['#09a678', '#43e8b6'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.baseData ? this.baseData.office_not_rent : 0 },
              { value: this.baseData ? this.baseData.office_has_rend : 0 },
            ]
          }
        ]
      });
      let myChart2 = this.$echarts.init(document.getElementById('myChart2'))
      myChart2.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['70%', '80%'],
            color: ['#1553ba', '#2a75f2'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.baseData ? this.baseData.business_not_rent : 0 },
              { value: this.baseData ? this.baseData.business_has_rent : 0 },
            ]
          }
        ]
      });


      let myChart3 = this.$echarts.init(document.getElementById('myChart3'))
      myChart3.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['70%', '80%'],
            color: ['#13a1a0', '#2dfffe'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.baseData ? this.baseData.car_not_rent : 0 },
              { value: this.baseData ? this.baseData.car_has_rent : 0 },
            ]
          }
        ]
      });
    },
    async getData() {
      const res = await getBaseData()
      const { data, msg } = res;
      let itemData = {};
      if (msg == 'success' && data.length > 0) {
        itemData = data[0]
        for (let key in itemData) {
          itemData[key] = Number(itemData[key])
        }
        this.baseData = itemData;
        this.echartsload()
        console.log('园区概况数据', itemData)
      }
    }
  },
  mounted() {
    this.getData()
    setTimeout(() => {
      this.showecharts = true
    }, 800)
  }
}
</script>

<style lang="less" scoped>
.zhongguancun_left_content h5 i {
  width: 7px;
  height: 16px;
  display: block;
  margin-top: 4px;
}

.zhongguancun_left_content h5 p {
  margin-left: 4px;
  font-size: 17px;
  font-family: 'DSDIGI1';
}

.zhongguancun_left_content .green {
  background: url(./../../assets/images/zhongguancunAttractTopLeftgreen.png) no-repeat;
  background-size: 100%;
}

.zhongguancun_left_content .blue {
  background: url(./../../assets/images/zhongguancunAttractTopLeftblue.png) no-repeat;
  background-size: 100%;
}

.zhongguancun_left_content .wathet {
  background: url(./../../assets/images/zhongguancunAttractTopLeftwathet.png) no-repeat;
  background-size: 100%;
}

.zhongguancun_left_content ul {
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.zhongguancun_left_content li>h5 {
  display: flex;
}

.zhongguancun_left_content ul li {
  width: 100%;
  height: 145px;
}

.top {
  /* height: 460px; */
}

.zhongguancun_left_content .top h5 {
  font-size: 17px;
}

.top .num_box {
  /* width: 188px; */
  height: 90px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.num_box_radius {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 12px;
  display: flex;
  box-sizing: border-box;
}

.num_box_radius_green {
  border: 1px solid #285b5b;
}

.num_box_radius_blue {
  border: 1px solid #243f62;
}

.num_box_radius_wathet {
  border: 1px solid #275a5a;
}

.num_box_radius_in {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
  display: flex;
}

.num_box_radius_in_green {
  border: 1px solid #52b08a;
}

.num_box_radius_in_blue {
  border: 1px solid #2c6fc9;
}

.num_box_radius_in_wathet {
  border: 1px solid #4ecfcf;
}

.num_box_radius_img {
  width: 22px;
  height: 20px;
  margin: auto;
}

.num_box_radius_in .area {
  background: url(../../assets/images/area.png) no-repeat;
  background-position: 100%;
}

.num_box_radius_in .Planfloor {
  background: url(../../assets/images/Planfloor.png) no-repeat;
  background-position: 100%;
}

.num_box_radius_in .vehicle {
  background: url(../../assets/images/vehicle.png) no-repeat;
  background-position: 100%;
}

.num_box_left {
  width: 188px;
}

.num_box_left_green {
  background: url(../../assets/images/Planningboxgreen.png) no-repeat;
  background-size: 100%;
}

.num_box_left_blue {
  background: url(../../assets/images/Planningboxblue.png) no-repeat;
  background-size: 100%;
}

.num_box_left_wathet {
  background: url(../../assets/images/Planningboxwathet.png) no-repeat;
  background-size: 100%;
}

.num_box_leftCont {
  padding-left: 12px;
  box-sizing: border-box;
}

.num_box_leftCont h3 {
  font-size: 25px;
}

.num_box_leftCont span {
  margin-top: -2px;
  /* font-family: 'DSDIGI1'; */
}

.num_box_right {
  width: 215px;
  display: flex;
  justify-content: space-between;
}

.echarts_radius {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
}

.echarts_radius_green {
  border: 1px solid #2a6753;
}

.echarts_radius_blue {
  border: 1px solid #1d3c68;
}

.echarts_radius_wathet {
  border: 1px solid #244f4f;

}

.echarts_radius p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
}

.echarts_radius p i {
  font-weight: 500;
  font-size: 12px;
}

.num_box_text {
  width: 105px;
  height: 90px;
  display: flex;
  flex-wrap: wrap;
}

.num_box_text>div {
  width: 100%;
  height: 45px;
  position: relative;
}

.num_box_text>div::after {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  top: 8px;
  left: 2px;
}

.num_box_area>div::after {
  background: #43e8b6;
}

.num_box_Planfloor>div::after {
  background: #2a75f2;
}

.num_box_wathet>div::after {
  background: #58ebea;
}

.dancolorone::after {
  background: rgb(8, 170, 123) !important;
}

.dancolortwo::after {
  background: rgb(21, 83, 186) !important;
}

.dancolorthree::after {
  background: rgb(19, 161, 160) !important;
}

.center {
  width: 100%;
  height: 205px;
  box-sizing: border-box;
  padding-top: 27px;
}

.center_box {
  width: 100%;
  height: 108px;
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.center_left_box {
  width: 252px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: url(../../assets/images/AirconditioningTime.png) no-repeat;
  background-size: 100%;
}

.center_left_box h5 {
  width: 30px;
  writing-mode: tb-rl;
  display: flex;
  font-weight: 500;
}

.center_left_box p {
  margin: auto;
}

.center_left_box>div {
  width: 220px;
  padding: 23px 4px;
  box-sizing: border-box;
}

.center_left_box>div>p {
  font-size: 15px;
  letter-spacing: 1px
}

.center_right_box {
  width: 138px;
  height: 100%;
  background: url(../../assets/images/Elevator.png) no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
}

.center_right_box h5 {
  width: 30px;
  writing-mode: tb-rl;
  display: flex;
  font-weight: 500;
}

.center_right_box div p {
  margin: auto;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 88px;
}

.center_right_box h5 p {
  margin: auto;
}

.center_right_box div {
  width: 102px;
}

.bottom {
  height: 260px;
}

.bottom h3 {
  font-size: 18px;
  margin-top: 14px;
}

.bottom_center {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-top: 18px;
}

.bottom_center>div {
  width: 47%;
  display: flex;
  justify-content: space-between;
}

.bottom_center>div i {
  width: 22px;
  height: 22px;
  display: block;
}

.bottom_center>div p {
  /* display: inline-block; */
  width: 170px;
}

.bottom_center>div:nth-child(1) i {
  background: url(../../assets/images/zhaoshangdili1.png) no-repeat;
  background-size: 100%;
}

.bottom_center>div:nth-child(2) i {
  background: url(../../assets/images/zhaoshangdili2.png) no-repeat;
  background-size: 100%;
}

.bottom_center>div:nth-child(3) i {
  background: url(../../assets/images/zhaoshangdili3.png) no-repeat;
  background-size: 100%;
}

.bottom_center>div:nth-child(4) i {
  background: url(../../assets/images/zhaoshangdili4.png) no-repeat;
  background-size: 100%;
}

.bottom_train {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.bottom_train i {
  width: 22px;
  height: 22px;
  background: url(../../assets/images/zhaoshangdili5.png) no-repeat;
  background-size: 100%;
  display: block;
}

.bottom_train p {
  width: 394px;
}

.num {
  font-family: 'DSDIGI1';
  font-size: 34px;
  font-weight: bold;
}

.numarea {
  font-family: 'DSDIGI1';
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 2px;
}
</style>
